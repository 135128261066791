export const auth = {
  DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  CALLBACK_URL: process.env.REACT_APP_AUTH_CALLBACK,
};

export const links = {
  MENTORSHIP_GUIDELINES:
    'https://codingcoach.io/guidelines/mentorship-guidelines',
};
