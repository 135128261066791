export default [
  '.net',
  '.netcore',
  '3d',
  'a11y',
  'abap',
  'accessibility',
  'adobe',
  'ai',
  'ajax',
  'algorithms',
  'android',
  'angular',
  'angularjs',
  'apache-ecosystem',
  'apex',
  'apache-airflow',
  'apache-cassandra',
  'apache-flink',
  'apache-hadoop',
  'apache-kafka',
  'apache-spark',
  'api',
  'api-design',
  'apps script',
  'architecture',
  'arduino',
  'asp.net',
  'asp.net core',
  'automation',
  'aws',
  'azure',
  'backend',
  'best-practices',
  'big-data',
  'bitcoin',
  'blazor',
  'blogging',
  'bootstrap',
  'bubble',
  'build',
  'c',
  'c++',
  'c++/cli',
  'c++/winrt',
  'c-sharp',
  'career',
  'ci',
  'clojure',
  'cloud',
  'codeigniter',
  'command-line',
  'compassion',
  'computability',
  'css',
  'css-in-js',
  'd3js',
  'data',
  'data structures',
  'database',
  'ddd',
  'design',
  'design system',
  'devops',
  'distributed systems',
  'django',
  'docker',
  'driver development',
  'e6',
  'ecommerce',
  'education',
  'elastic search',
  'electron.js',
  'electronics',
  'elixir',
  'elm',
  'embedded',
  'embedded software',
  'ethereum',
  'express',
  'f-sharp',
  'figma',
  'firebase',
  'firmware',
  'flutter',
  'fp',
  'frontend',
  'fullstack',
  'functional',
  'gamedev',
  'gatsby',
  'git',
  'go',
  'golang',
  'graphql',
  'growth hacking',
  'gui',
  'hardware',
  'haskell',
  'html',
  'hugo',
  'ibm cloud',
  'inclusion',
  'industrial automation',
  'integration',
  'interviews',
  'intro',
  'ionic',
  'ios',
  'jamstack',
  'java',
  'javascript',
  'jest',
  'jquery',
  'julia',
  'kotlin',
  'kubernetes',
  'laravel',
  'leadership',
  'linux',
  'lua',
  'magento',
  'management',
  'markup',
  'matlab',
  'mean stack',
  'microservices',
  'mobile',
  'mobx',
  'modern php',
  'mongodb',
  'mssql',
  'mysql',
  'nestjs',
  'nextjs',
  'ngrx',
  'nodejs',
  'nosql',
  'objc',
  'ocaml',
  'opensource',
  'operations',
  'p2p',
  'performance',
  'phoenix fwk',
  'php',
  'platform',
  'podcast',
  'postgresql',
  'powershell',
  'principles',
  'product',
  'programming',
  'prototyping',
  'pug',
  'pwa',
  'python',
  'pytorch',
  'quality-assurance',
  `r`,
  'rails',
  'react native',
  'reactjs',
  'redis',
  'redux',
  'remote work',
  'rest',
  'restful api',
  'ruby',
  'ruby on rails',
  'rust',
  'rx',
  'rxjs',
  'sap',
  'salesforce',
  'sass',
  'scala',
  'scalability',
  'scrum',
  'security',
  'selenium',
  'serverless',
  'sketch',
  'sketch plugins',
  'socket.io',
  'software-design',
  'software-quality',
  'solidus',
  'speaking',
  'spree',
  'spring',
  'sql',
  'sql server',
  'start-ups',
  'stenciljs',
  'stimulusjs',
  'svg',
  'swift',
  'swoole',
  'symfony',
  'sysadmin',
  'tailwind',
  'tdd',
  'tensorflow',
  'testing',
  'tooling',
  'tsql',
  'typescript',
  'ui',
  'umbraco',
  'unit testing',
  'unity',
  'uwp',
  'ux',
  'visual design',
  'vuejs',
  'watson',
  'web',
  'web automation',
  'web typography',
  'web apis',
  'web development',
  'webassembly',
  'webflow',
  'webscraping',
  'wireframing',
  'wordpress',
  'xamarin',
  'xamarin forms',
  'xaml',
  'yaml',
];
