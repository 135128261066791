const privacyPolicy = `<h4>Effective date: October 03, 2018</h4>

Coding Coach ("us", "we", or "our") operates the website and the Coding Coach mobile application (the "Service").

This page informs you of our policies regarding the collection, use, and disclosure ofn personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy for Coding Coach is managed through Free Privacy Policy.

We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
`;

export default privacyPolicy;
